@import './fonts.scss';

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'RoobertCEZ', Arial, sans-serif;
    background: var(--default-background, #F4F1F0);
    transition: background-color 1s ease;
}

b {
    color: var(--default-black, #03031A);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.gdpr {
    text-decoration: underline;
    cursor: pointer;

    & .padding {
        padding-bottom: 20px;
    }
}

.overlay {
    z-index: 10;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
}

.overlay.visible {
    display: flex;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #23885F;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}