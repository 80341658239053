.btn-gdpr-close {
    display: flex;
    justify-content: center;
    /* Horizontální zarovnání do středu */
    align-items: center;
    /* Vertikální zarovnání do středu */
    width: 50%;
    border-radius: 0 0 0px 0px;
    padding: 20px 20px;
    background: var(--default-secondary, #CFC8C8);
    border: none;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.btn-gdpr-close:hover {
    background: var(--default-secondary, #A19C9F);
}