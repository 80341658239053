.new-request-button {
    width: inherit;
    display: flex;
    padding: 20px 80px 20px 32px;
    border-radius: 0 0 8px 8px;
    align-items: center;
    background: var(--default-secondary, #CFC8C8);
    border: none;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.new-request-button:hover {
background: var(--default-secondary, #A19C9F);
}