@import '../../scss/fonts.scss';

.wrapper {
    text-align: left;
    //height: 85vh;
    display: flex;
    flex-direction: column;

    .container {
        background-color: white;
        padding: 0 32px;
        border-radius: 8px 8px 8px 8px;
        padding-bottom: 20px;
        flex: 1;
    }
}

.location {
    display: flex;
    flex-direction: row;
}

.radio-item {
    margin: 0 20px;
}

.cez-image {
    margin: 15px 0;
    display: flex;
    align-items: center;
    /* Center vertically */
}

.cez-image img {
    margin-right: 10px;
    vertical-align: middle;
}

.cez-image p {
    margin: 0;
    color: var(--cez-grey, #63666A);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
}


.form-text {
    margin: 20px 0 10px 0;
    display: flex;
    align-items: center;
}

.form-text h1 {
    margin: 0 15px 0 0;
    /* Add right margin to create space between h1 and image */
    color: #161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.form-text h2 {
    margin: 0 15px 0 0;
    color: #03031A;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.form-label,
.form-label-bellow {
    color: #434347;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-label-bellow {
    margin: -10px 0 10px 0;
    font-size: 13px;
}

.form-label-bellow-h1 {
    margin: 0 0 10px 0;
    font-size: 13px;
}

.field-label {
    flex: 1;
    /* Text zabere dostupný prostor na levé straně */
    text-align: left;
    /* Text vlevo */
}

.text-counter {
    margin-left: 8px;
}

.form-text img {
    margin-left: auto;
    /* Push the image to the right side */
    vertical-align: middle;
}

.form-component {
    margin: 15px 0;

    .top {
        margin: 16px 0;
        border-collapse: separate;
        border-spacing: 10px 0;
        border-top: 1px solid var(--default-secondary, #CFC8C8);
    }
}

.tooltip-icon {
    padding: 6px;
    gap: 8px;

    &.description {
        margin-left: 10px;
    }

    &:hover {
        border-radius: 24px;
        background: var(--default-secondary, #CFC8C8);
    }
}

.tooltip-inner {
    max-width: 300px;
}

.top {
    margin: 10px 0;
    border-collapse: separate;
    border-spacing: 10px 0;
    border-top: 1px solid var(--default-secondary, #CFC8C8);
}

.bottom {
    padding-bottom: 20px;
    margin: 16px 0;
    border-collapse: separate;
    border-spacing: 10px 0;
    border-bottom: 1px solid var(--default-secondary, #CFC8C8);
}

.accordion-button:focus {
    background-color: white;
    border: white;
    box-shadow: var(--default-secondary);
}

.accordion-button:not(.collapsed) {
    background-color: white;
    border: white;
}

.add-button {
    width: 180px;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #03031A;
    background-color: white;
    justify-content: space-between;
    /* Ikonka vpravo */
}

.button-text {
    flex: 1;
    /* Text zabere dostupný prostor na levé straně */
    text-align: left;
    /* Text vlevo */
}

.icon {
    margin-left: 8px;
    /* Nastavení mezery mezi textem a ikonkou */
}

.add-button:hover {
    background-color: #EFEFEF;
}

.spacing {
    margin: 20px 0 20px 0;
}

.form-check-input {
    display: none;
}

.form-check-input .is-invalid {
    display: none;
    background-image: url('../../img/Radio-invalid.svg');
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    background-image: url('../../img/Radio-invalid.svg');
}

.form-check-label {
    background-image: url('../../img/Radio-unchecked.svg');
    background-position: left center; // Add this line
    background-repeat: no-repeat; // Add this line
    padding-left: 30px; // Add this line
    height: 20px;
    width: 20px;
    margin-right: 100px;
    position: relative;
    top: 5px;
}

.form-check-input:checked+.form-check-label {
    background-image: url('../../img/Radio-checked.svg');
}

.snap-tooltip {
    text-align: left;
}

.invalid-feedback-file {
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: red;
}

.visible {
    display: block;
}

.form-checkBox-label {
    top: -4px;
    height: 20px;
    margin-left: 20px;
    position: relative;
}

.form-checkBox-input {
    margin-left: -10px;
    width: 20px;
    height: 20px;
}