.submit-button {
    width:auto;
    display: flex;
    position: sticky;
    margin-top: -10px;
    margin-bottom: 20px;
    align-items: center;
    bottom: 0;
    border: none;
    color: white;
    cursor: pointer;
    padding: 20px 80px 20px 32px;
    border-radius: 0 0 8px 8px;
    transition: background-color 0.3s ease;
    background: var(--default-green, #005934);
    z-index: 3;
}

.submit-button:hover {
    background: var(--default-green, #004228);
}