@import '../../scss/fonts.scss';

.wrapper-failure {
    text-align: left;
    display: flex;
    flex-direction: column;

    .container-failure {
        background-color: white;
        padding: 0 32px;
        border-radius: 8px 8px 0 0;
        flex: 1;
    }
}

.cez-image {
    margin: 15px 0;
    display: flex;
    align-items: center;
    /* Center vertically */
}

.cez-image img {
    margin-right: 10px;
    vertical-align: middle;
}

.cez-image p {
    margin: 0;
    color: var(--cez-grey, #63666A);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
}
